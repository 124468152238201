// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/providers/LocaleProvider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/providers/LocaleProvider.tsx");
  import.meta.hot.lastModified = "1736795300702.8044";
}
// REMIX HMR END

import { createContext, useContext, useMemo } from 'react';
const Context = createContext(null);
export const LocaleContextProvider = ({
  locales,
  timezone,
  children
}) => {
  _s();
  const value = useMemo(() => ({
    locales,
    timezone
  }), [locales, timezone]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
_s(LocaleContextProvider, "tPauEVZ6EeuERV9ttvKTwQ7++Gw=");
_c = LocaleContextProvider;
const throwIfNoProvider = () => {
  throw new Error('Please wrap your application in a LocaleContextProvider.');
};
export const useLocales = () => {
  _s2();
  const ctx = useContext(Context) ?? throwIfNoProvider();
  return ctx;
};
_s2(useLocales, "gDsCjeeItUuvgOWf1v4qoK9RF6k=");
var _c;
$RefreshReg$(_c, "LocaleContextProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;