// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/entry.client.tsx"
);
import.meta.hot.lastModified = "1740500125916.5955";
}
// REMIX HMR END

import * as Sentry from '@sentry/remix'
import { hydrateRoot } from 'react-dom/client'
import { startTransition, StrictMode, useEffect } from 'react'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { LocaleContextProvider } from './providers/LocaleProvider'
import memoizeOne from 'memoize-one'
import { getStoreTimeZone } from '~common/utils/timezone'

const isSentryEnabled =
  (window as any).ENV?.DISABLE_SENTRY !== 'true' &&
  (window as any).ENV?.SENTRY_DSN

if (isSentryEnabled) {
  Sentry.init({
    dsn: (window as any).ENV?.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // Replay is only available in the client
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      (window as any).ENV?.VENDURE_API_URL?.replace(/\?.*$/, ''),
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
// import dotenv from 'dotenv'
// dotenv.config()
const locales = memoizeOne(() => [...window.navigator.languages])
const timezone = memoizeOne(getStoreTimeZone)
// `startTransition` keeps React hydration from blocking the main thread, this
// is useful in a server-rendered app like Remix because the UI is already on
// the page the user could start scrolling. If you have a very large document
// hydration could take hundreds of milliseconds, and make the scroll janky.
// `startTransition` keeps the main thread from blocking so the user can scroll
// while React hydrates without jank.
startTransition(() => {
  // Notice that this is your own application code that calls `hydrateRoot`
  hydrateRoot(
    document,
    <StrictMode>
      <LocaleContextProvider locales={locales()} timezone={timezone()}>
        <RemixBrowser />
      </LocaleContextProvider>
    </StrictMode>,
  )
})
